import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateOnce animateIn="fadeInLeft">
            <Image
              src="/man-svgrepo-com.svg"
              alt="man-svgrepo"
            />
          </ScrollAnimation>
          <div className="AboutBio">

            <ScrollAnimation animateOnce animateIn="fadeInLeft">

              <h1>👋 Hi, I'm Terry Chan</h1>

              <p>A passionate Full-Stack Software Engineer based in Toronto, I craft elegant code and bring complex ideas to life. With a strong focus on building scalable and high-performance applications, I specialize in both web and desktop solutions using modern technologies.</p>
            
            </ScrollAnimation>

            <br />


            <ScrollAnimation animateOnce animateIn="fadeInLeft">

              <h2>🌟 A Bit About Me</h2>
              <ul>
                <li>💻 <strong>Tech Enthusiast</strong> with a keen interest in everything from Kubernetes clusters to sleek UI design.</li>
                <li>👨‍💻 Experienced in <strong>leading projects</strong>, <strong>mentoring teams</strong>, and enhancing <strong>application performance</strong>.</li>
                <li>💼 Currently working at <strong>Fintros Inc.</strong> as a Full Stack Developer.</li>
                <li>🎓 MSc in Information Technology from <strong>The Hong Kong Polytechnic University</strong>.</li>
                <li>🐱 Cat Slave 💘</li>
              </ul>

            </ScrollAnimation>


            <br />

            <ScrollAnimation animateOnce animateIn="fadeInLeft">

              <h2>🌱 Always Learning & Building</h2>
              <p>My journey in tech is driven by curiosity and a love for solving real-world problems. From PWA development to automating workflows, I’m always excited to take on new challenges. Check out more of my work and feel free to connect!</p>
            </ScrollAnimation>

            <br />

            <ScrollAnimation animateOnce animateIn="fadeInLeft">
              <div className="tagline2">
                I have become confident using the following technologies:
              </div>
            </ScrollAnimation>

``
            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateOnce animateIn="fadeInLeft" key={`texh-animation-${index}`}>
                  <Tech key={`tec-wrapper-${index}`} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
